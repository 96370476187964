import React, { Component } from "react";
import NavEN from "../Symbols/NavEN";
import FooterEN from "../Symbols/FooterEN";
import MetaTags from "react-meta-tags";
import "../../stylesheets/AboutUs.css";
import REACH from "../../assets/reach.pdf";
import COC from "../../assets/CoC_pancon.pdf";
import IATFEN from "../../assets/iatf_en.pdf";
import ISOEN from "../../assets/iso_9001_en.pdf";
import ROHS from "../../assets/rohs.pdf";
import LFEN from "../../assets/Lagerfaehigkeit_en.pdf";

class EnvironmentEN extends Component {
  constructor(props) {
    super(props);
    this.intro = React.createRef();
  }

  introScroll = () => {
    let element = this.intro.current;
    let headerOffset = 45;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <div className="content">
        <MetaTags>
          <title>Pancon - Environment</title>
          <meta
            name="description"
            content="Pancon, based in Germany, is producing and distributing electromechanical components since 1968."
          />
          <meta property="og:title" content="Pancon - Environment" />
          <meta
            property="og:description"
            content="Pancon, based in Germany, is producing and distributing electromechanical components since 1968."
          />
          <link
            rel="alternate"
            href="https://www.pancon.de/de/umwelt"
            hrefLang="de"
          ></link>
          <link
            rel="alternate"
            href="https://www.pancon.de/en/environment"
            hrefLang="en"
          ></link>
        </MetaTags>
        <NavEN />
        <div className="hero env">
          <div className="red-bg-hero"></div>
          <div className="hero-content">
            <h1 className="hero-heading">Environment</h1>
            <p className="hero-desc">Overview of our certificates</p>
            <button onClick={this.introScroll} className="default inverted">
              Learn more
            </button>
          </div>
        </div>
        <div
          ref={this.intro}
          id="intro"
          className="section first flex center-h"
        >
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2>REACH</h2>
            </div>
            <div className="half">
              <h4>Description</h4>
              <p className="body">
                Registration, Evaluation, Authorisation and Restriction of
                Chemicals (REACH) is a European Union Regulation of 18 December
                2006. REACH addresses the production and use of chemical
                substances, and their potential impacts on both human health and
                the environment
              </p>
              <a href={REACH} target={"_blank"} className="default env">
                Download
              </a>
            </div>
            <div className="half right-text">
              <h4>Further information</h4>
              <p className="body">
                <ul>
                  <li>
                    The{" "}
                    <a
                      target={"_blank"}
                      href="http://echa.europa.eu/de/"
                      className="accented"
                    >
                      Europäische Chemikalienagentur
                    </a>{" "}
                    (ECHA) is an agency of the European Union which manages the
                    technical, scientific and administrative aspects of the
                    Registration, Evaluation, Authorisation and Restriction of
                    Chemicals (REACH) system.
                  </li>
                  <li>
                    BDI - Bundesverband der Deutschen Industrie REACH- und der
                    CLP-Verordnung als{" "}
                    <a
                      target={"_blank"}
                      href="http://echa.europa.eu/de/"
                      className="accented"
                    >
                      BDI-Broschüre
                    </a>
                    .
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div className="section full env flex center-h center-v">
          <span className="bg-quote">
            "Look deep into nature, and then you will understand everything
            better."
          </span>
        </div>
        <div id="coc" className="section flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2>Code of Conduct</h2>
            </div>
            <div>
              <a href={COC} target={"_blank"} className="default env">
                Download
              </a>
            </div>
          </div>
        </div>
        <div id="rohs" className="section flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2>RoHS I / RoHS II</h2>
            </div>
            <div className="half">
              <h4>Description</h4>
              <p className="body">
                The Directive on the restriction of the use of certain hazardous
                substances in electrical and electronic equipment 2002/95/EC &
                2011/65/EC (RoHS).
              </p>
              <a href={ROHS} target={"_blank"} className="default env">
                Download
              </a>
            </div>
            <div className="half right-text">
              <h4>Further information</h4>
              <p className="body">
                <ul>
                  <li>
                    <a
                      target={"_blank"}
                      href="http://ec.europa.eu/environment/waste/rohs_eee/index_en.htm"
                      className="accented"
                    >
                      European Commission Environment Waste RoHS
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div id="iatf-iso" className="section flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2>Additional certificates</h2>
            </div>
            <div className="third">
              <h4>IATF</h4>
              <a href={IATFEN} target={"_blank"} className="default env">
                Download
              </a>
            </div>
            <div className="third">
              <h4>ISO 9001</h4>
              <a href={ISOEN} target={"_blank"} className="default env">
                Download
              </a>
            </div>
            <div className="third right-text">
              <h4>Optimal Shelf Life</h4>
              <a href={LFEN} target={"_blank"} className="default env">
                Download
              </a>
            </div>
          </div>
        </div>
        <FooterEN />
      </div>
    );
  }
}

export default EnvironmentEN;
