import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import About1 from "../../assets/about_1.jpg";
import MetaTags from "react-meta-tags";
import "../../stylesheets/AboutUs.css";
import { FaPeopleArrows } from "react-icons/fa";
import { FaProductHunt } from "react-icons/fa";
import { FaGlobe } from "react-icons/fa";

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.intro = React.createRef();
  }

  introScroll = () => {
    let element = this.intro.current;
    let headerOffset = 45;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;
  
    window.scrollTo({
         top: offsetPosition,
         behavior: "smooth"
    });
  }

  render() {
    return (
      <div className="content">
        <MetaTags>
          <title>Pancon - Über Uns</title>
          <meta
            name="description"
            content="Pancon mit Sitz in Bad Homburg, produziert und vertreibt elektromechanische Komponenten seit 1968."
          />
          <meta property="og:title" content="Pancon - Über uns" />
          <meta
            property="og:description"
            content="Pancon mit Sitz in Bad Homburg, produziert und vertreibt elektromechanische Komponenten seit 1968."
          />
          <link
            rel="alternate"
            href="https://www.pancon.de/de/ueber-uns"
            hrefLang="de"
          ></link>
          <link
            rel="alternate"
            href="https://www.pancon.de/en/about-us"
            hrefLang="en"
          ></link>
        </MetaTags>
        <NavDE />
        <div className="hero about">
          <div className="red-bg-hero"></div>
          <div className="hero-content">
            <h1 className="hero-heading">Über uns</h1>
            <p className="hero-desc">
              Pancon mit Sitz in Bad Homburg, produziert und vertreibt
              elektromechanische Komponenten seit 1968
            </p>
            <button onClick={this.introScroll} className="default inverted">Mehr erfahren</button>
          </div>
        </div>
        <div ref={this.intro} id="intro" className="section flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2>Geschichte</h2>
            </div>
            <div className="half">
              <p className="body">
                <ul>
                  <li>1968-1997 Connector Division of Panduit / USA</li>
                  <li>1997-2011 ITW / USA</li>
                  <li>2011-2019 Milestone Partners / USA</li>
                  <li>2020 LIB Beteiligungsgesellschaft mbH / Germany</li>
                </ul>
              </p>
            </div>
            <div className="half w-image">
              <img src={About1}></img>
            </div>
          </div>
        </div>
        <div className="section full about-1 flex center-h center-v">
          <div className="half about-img-2"></div>
          <div className="half red-bg">
            <h4>Über uns</h4>
            <h2>Pancon - Das Unternehmen</h2>
            <ul>
              <li>Inhabergeführtes Unternehmen</li>
              <li>
                Zukunftsfähige Ausrichtung durch Entwicklung und Produktion von
                neuen Produktlinien
              </li>
              <li>
                Motivierende Umgebung für Mitarbeiter & Partner - Mehr als 90%
                der Mitarbeiter sind mindestens 5 Jahre im Unternehmen, einige
                sogar über 40 Jahre
              </li>
              <li>Stabiles Wertefundament</li>
              <li>
                Innovativer Hersteller für elektronische Steckverbinder in
                Europa
              </li>
              <li>
                Stabiler und langjähriger Kundenstamm aus unterschiedlichen
                Branchen
              </li>
              <li>Finanzielle Sicherheit durch eine hohe Eigenkapitalquote</li>
            </ul>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2>Internationale Ausrichtung</h2>
            </div>
            <div className="half">
              <h4>Wir produzieren in Europa und Asien</h4>
              <p className="body">
                Mit unseren drei Produktionsstandorten in Europa und Asien, sind
                wir in der Lage die Anforderungen unserer Kunden zu erfüllen.
                <br></br>
                Mittels einer internationalen Logistik und der Nutzung der
                Kapazitäten unserer Distributoren, können wir schnell und
                zuverlässig liefern.
              </p>
            </div>
            <div className="half right-text">
              <h4>Unsere Kunden produzieren in</h4>
              <p className="body">
                <ul>
                  <li>Europa</li>
                  <li>Nordamerika / Südamerika</li>
                  <li>Asien</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div className="section full about">
          <div className="center">
            <h4>Zahlen und Fakten</h4>
          </div>
          <div className="container flex center-v spa">
            <div className="third flex center-v col">
              <FaPeopleArrows size={48} />
              <p>{">"} 100 Kunden</p>
              <span>in 30 Ländern</span>
            </div>
            <div className="third flex center-v col">
              <FaProductHunt size={48} />
              <p>10.000 Artikel</p>
              <span>aktiv</span>
            </div>
            <div className="third flex center-v col">
              <FaGlobe size={48} />
              <p>3 Produktionsstandorte</p>
              <span>weltweit</span>
            </div>
          </div>
        </div>
        <FooterDE />
      </div>
    );
  }
}

export default AboutUs;
